body, html {
    height: 100%;
    background: #F3F3F3FF;
    font-family: 'Raleway', sans-serif;
}

#app {
    margin: 0 auto;
    width: 100%;
}

.box {
    padding: 35px;
    background: #ffffff;
    -webkit-box-shadow: 0 0 65px 0 rgba(230, 230, 230, 1);
    -moz-box-shadow: 0 0 65px 0 rgba(230, 230, 230, 1);
    box-shadow: 0 0 65px 0 rgba(230, 230, 230, 1);
    border-radius: 14px;
}

.container {
    max-width: 566px;
}

.font-value {
    font-family: monospace, sans-serif;
}